import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage, FormattedRelativeTime, useIntl } from "react-intl";
import styles from "./MediaTiles.scss";
import { ReactComponent as PeopleIcon } from "../icons/People.svg";
import { ReactComponent as StarIcon } from "../icons/Star.svg";
import { ReactComponent as AddIcon } from "../icons/Add.svg";
import { ReactComponent as PenIcon } from "../icons/Pen.svg";
import { ReactComponent as DuplicateIcon } from "../icons/Duplicate.svg";
import { ReactComponent as SearchIcon } from "../icons/Search.svg";
import { ReactComponent as HelpIcon } from "../icons/Help.svg";
import { ReactComponent as ExternalLinkIcon } from "../icons/ExternalLink.svg";

let isButtonStop = false;
const PUBLISHER_FOR_ENTRY_TYPE = {
  sketchfab_model: "Sketchfab",
  twitch_stream: "Twitch"
};

function useThumbnailSize(isImage, isAvatar, imageAspect) {
  return useMemo(() => {
    let imageHeight = 220;
    if (isAvatar) imageHeight = Math.floor(imageHeight * 1.5);

    // Aspect ratio can vary per image if its an image result. Avatars are a taller portrait aspect, o/w assume 720p
    let imageWidth;
    if (isImage) {
      imageWidth = Math.floor(Math.max(imageAspect * imageHeight, imageHeight * 0.85));
    } else if (isAvatar) {
      imageWidth = Math.floor((9 / 16) * imageHeight);
    } else {
      imageWidth = Math.floor(Math.max((16 / 9) * imageHeight, imageHeight * 0.85));
    }

    return [imageWidth, imageHeight];
  }, [isImage, isAvatar, imageAspect]);
}

function useThumbnail(entry, processThumbnailUrl) {
  const isImage = entry.type.endsWith("_image");
  const isAvatar = entry.type === "avatar" || entry.type === "avatar_listing";
  const imageAspect = entry.images.preview.width / entry.images.preview.height;

  const [thumbnailWidth, thumbnailHeight] = useThumbnailSize(isImage, isAvatar, imageAspect);

  const thumbnailUrl = useMemo(() => {
    return processThumbnailUrl ? processThumbnailUrl(entry, thumbnailWidth, thumbnailHeight) : entry.images.preview.url;
  }, [entry, thumbnailWidth, thumbnailHeight, processThumbnailUrl]);

  return [thumbnailUrl, thumbnailWidth, thumbnailHeight];
}

/**
 * ブラウザにアバターやシーンの名前や開催期間を表示する
 */
function BaseTile({ as: TileComponent, className, name, description, tall, wide, children, duration, ...rest }) {
  let additionalProps;

  if (TileComponent === "div") {
    additionalProps = {
      tabIndex: "0",
      role: "button"
    };
  }

  return (
    <TileComponent
      className={classNames(styles.mediaTile, { [styles.tall]: tall, [styles.wide]: wide }, className)}
      {...additionalProps}
      {...rest}
    >
      <div className={styles.thumbnailContainer}>{children}</div>
      {(name || description || duration) && (
        <div className={styles.info}>
          {duration && <small className={styles.duration}>{duration}</small>}
          <b>{name}</b>
          {description && <small className={styles.description}>{description}</small>}
        </div>
      )}
    </TileComponent>
  );
}

BaseTile.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  name: PropTypes.string,
  duration: PropTypes.string,
  description: PropTypes.node,
  children: PropTypes.node,
  tall: PropTypes.bool,
  wide: PropTypes.bool
};

BaseTile.defaultProps = {
  as: "div"
};

function TileAction({ className, children, ...rest }) {
  return (
    <button type="button" className={classNames(styles.tileAction, className)} {...rest}>
      {children}
    </button>
  );
}

TileAction.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

/**
 * 開催期間外であることをユーザーに通知する処理
 */
function showOutsidePeriodMessage(roomInaccessibleText) {
  if (!isButtonStop) {
    isButtonStop = true;

    // 画面いっぱいに半透明のマスクを表示
    const mask = document.createElement("div");
    mask.className = styles.roomModalMask;
    document.body.appendChild(mask);

    const outsidePeriodMessage = document.createElement("div");
    outsidePeriodMessage.className = styles.outsidePeriodMessage;
    mask.appendChild(outsidePeriodMessage);

    const textContent = document.createElement("div");
    textContent.textContent = roomInaccessibleText;
    textContent.className = styles.textContent;
    outsidePeriodMessage.appendChild(textContent);

    const modelCloseButton = document.createElement("div");
    modelCloseButton.className = styles.modelCloseButton;
    outsidePeriodMessage.appendChild(modelCloseButton);

    // 閉じるボタンを作成
    const closeButton = document.createElement("button");
    closeButton.textContent = "閉じる";
    closeButton.className = styles.closeButton;
    modelCloseButton.appendChild(closeButton);

    // マスククリックでモーダルを閉じる
    mask.addEventListener("click", () => {
      document.body.removeChild(mask);
      isButtonStop = false;
    });

    // マウスカーソルが閉じるボタンに乗った時のイベントリスナーを追加
    closeButton.addEventListener("mouseover", event => {
      event.stopPropagation();
      closeButton.style.backgroundColor = "#007ab8";
      closeButton.style.color = "#ffffff";
    });

    // マウスカーソルが閉じるボタンから離れた時のイベントリスナーを追加
    closeButton.addEventListener("mouseout", event => {
      event.stopPropagation();
      closeButton.style.backgroundColor = "#ffffff";
      closeButton.style.color = "#333";
    });

    // 閉じるボタンのクリックイベントリスナーを追加
    closeButton.addEventListener("click", event => {
      event.stopPropagation();
      document.body.removeChild(mask);
      isButtonStop = false;
    });
  }
}

export function CreateTile({ label, type, ...rest }) {
  return (
    <BaseTile className={styles.createTile} wide={type === "scene"} tall={type === "avatar"} {...rest}>
      <div className={styles.createTileContent}>
        <AddIcon width={48} height={48} />
        <p>{label}</p>
      </div>
    </BaseTile>
  );
}

CreateTile.propTypes = {
  label: PropTypes.node,
  type: PropTypes.string
};

/**
 * トップページやシーン一覧、アバター一覧に表示するシーンやアバター（画像、ビデオ、アバター、シーンなど）を表示するためのタイルを生成
 */
export function MediaTile({ entry, processThumbnailUrl, onClick, onEdit, onShowSimilar, onCopy, onInfo, ...rest }) {
  const intl = useIntl();
  const creator = entry.attributions && entry.attributions.creator;
  const publisherName =
    (entry.attributions && entry.attributions.publisher && entry.attributions.publisher.name) ||
    PUBLISHER_FOR_ENTRY_TYPE[entry.type];

  const [thumbnailUrl, thumbnailWidth, thumbnailHeight] = useThumbnail(entry, processThumbnailUrl);

  // 現在の日時を取得
  const now = new Date();

  // 開催期間を取得
  const startPublishRoom = entry.published_at ? new Date(entry.published_at) : null;
  const endPublishRoom = entry.unpublished_at ? new Date(entry.unpublished_at) : null;

  let isNowAfterStart = true;
  let isNowAfterEnd = true;
  let isOutsidePeriod = true;
  let roomInaccessibleText;

  // 開催期間外かどうかを判定
  if (startPublishRoom && startPublishRoom > now) {
    isNowAfterStart = false;
    roomInaccessibleText = "ルーム開催までしばらくお待ちください";
    isOutsidePeriod = false;
  }
  if (endPublishRoom && now > endPublishRoom) {
    isNowAfterEnd = false;
    roomInaccessibleText = "ルームは終了しました";
    isOutsidePeriod = false;
  }
  if (!isNowAfterStart || !isNowAfterEnd) {
    isOutsidePeriod = false;
  }

  const publishedText = startPublishRoom
    ? `${startPublishRoom.getFullYear()}/${startPublishRoom.getMonth() + 1}/${startPublishRoom.getDate()} 
      ${startPublishRoom.getHours().toString().padStart(2, "0")}:${startPublishRoom
        .getMinutes()
        .toString()
        .padStart(2, "0")}`
    : "";
  const unpublishedText = endPublishRoom
    ? `${endPublishRoom.getFullYear()}/${endPublishRoom.getMonth() + 1}/${endPublishRoom.getDate()} 
      ${endPublishRoom.getHours().toString().padStart(2, "0")}:${endPublishRoom
        .getMinutes()
        .toString()
        .padStart(2, "0")}`
    : "";

  return (
    <BaseTile
      // Entryのタイプを判別
      wide={entry.type === "scene" || entry.type === "scene_listing" || entry.type === "room"}
      tall={entry.type === "avatar" || entry.type === "avatar_listing"}
      name={entry.name}
      // Entryのタイプがルームの時、開催期間を取得
      duration={
        (entry.type === "scene" || entry.type === "scene_listing" || entry.type === "room") &&
        (startPublishRoom || endPublishRoom)
          ? `日程: ${publishedText} ~ ${unpublishedText}`
          : ""
      }
      description={
        <>
          {creator && creator.name === undefined && <span>{creator}</span>}
          {creator && creator.name && !creator.url && <span>{creator.name}</span>}
          {creator && creator.name && creator.url && (
            <a href={creator.url} target="_blank" rel="noopener noreferrer">
              {creator.name}
            </a>
          )}
          {publisherName && (
            <>
              <a href={entry.url} target="_blank" rel="noopener noreferrer">
                <ExternalLinkIcon /> {publisherName}
              </a>
            </>
          )}
          {entry.last_activated_at && (
            <small>
              <FormattedMessage
                id="media-tile.joined-room"
                defaultMessage="Joined {relativeTime}"
                values={{
                  relativeTime: (
                    <FormattedRelativeTime
                      updateIntervalInSeconds={10}
                      value={(new Date(entry.last_activated_at).getTime() - Date.now()) / 1000}
                    />
                  )
                }}
              />
            </small>
          )}
        </>
      }
      {...rest}
    >
      <a
        className={styles.thumbnailLink}
        href={isOutsidePeriod ? entry.url : null}
        rel="noreferrer noopener"
        onClick={event => {
          if (isOutsidePeriod) {
            onClick && onClick(event);
          } else {
            showOutsidePeriodMessage(roomInaccessibleText);
          }
        }}
      >
        {entry.images.preview.type === "mp4" ? (
          <video
            muted
            autoPlay
            playsInline
            loop
            src={thumbnailUrl}
            alt={entry.name}
            width={thumbnailWidth}
            height={thumbnailHeight}
          />
        ) : (
          <img src={thumbnailUrl} alt={entry.name} width={thumbnailWidth} height={thumbnailHeight} />
        )}
      </a>
      {entry.favorited && <StarIcon className={styles.favoriteIcon} />}
      {entry.member_count !== undefined && (
        <div className={styles.memberCount}>
          <PeopleIcon /> <span>{entry.member_count}</span>
        </div>
      )}
      <div className={styles.tileActions}>
        {entry.type === "avatar" && (
          <TileAction
            title={intl.formatMessage({ id: "media-tile.action.edit-avatar", defaultMessage: "Edit avatar" })}
            onClick={onEdit}
          >
            <PenIcon />
          </TileAction>
        )}
        {entry.type === "scene" && entry.project_id && (
          <TileAction
            onClick={onEdit}
            title={intl.formatMessage({ id: "media-tile.action.edit-scene", defaultMessage: "Edit scene" })}
          >
            <PenIcon />
          </TileAction>
        )}
        {entry.type === "avatar_listing" && (
          <TileAction
            title={intl.formatMessage({
              id: "media-tile.action.show-similar-avatars",
              defaultMessage: "Show similar avatars"
            })}
            onClick={onShowSimilar}
          >
            <SearchIcon />
          </TileAction>
        )}
        {entry.type === "avatar_listing" && entry.allow_remixing && (
          <TileAction
            title={intl.formatMessage({
              id: "media-tile.action.copy-avatar",
              defaultMessage: "Copy to my avatars"
            })}
            onClick={onCopy}
          >
            <DuplicateIcon />
          </TileAction>
        )}
        {entry.type === "scene_listing" && entry.allow_remixing && (
          <TileAction
            title={intl.formatMessage({
              id: "media-tile.action.copy-scene",
              defaultMessage: "Copy to my scenes"
            })}
            onClick={onCopy}
          >
            <DuplicateIcon />
          </TileAction>
        )}
        {entry.type === "room" && onInfo && entry.description && (
          <TileAction
            title={intl.formatMessage({
              id: "media-tile.action.room-info",
              defaultMessage: "Room info"
            })}
            onClick={onInfo}
          >
            <HelpIcon />
          </TileAction>
        )}
      </div>
    </BaseTile>
  );
}

MediaTile.propTypes = {
  entry: PropTypes.object.isRequired,
  processThumbnailUrl: PropTypes.func,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onShowSimilar: PropTypes.func,
  onCopy: PropTypes.func,
  onInfo: PropTypes.func
};
